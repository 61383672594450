export default {
    IntegralNum: 1,
    objList: [{
        id: 1,
        title: '优越服务',
        appImg: require('../image/integral-app-1.png'),
        p1: '案例：优越服务',
        p2: '合作客户：广东优越车网信息科技有限公司',
        p3: '案例内容：优越服务以积分商城为载体，依托强大的资源整合能力与海量一线品牌权益产品，采用“推荐办卡送积分-积分兑换-回报再推荐”的活动形式，助力广州农业银行实现精准用户拉新，带动业绩增长。',
    }, {
        id: 2,
        title: '小蚂蚁服务',
        appImg: require('../image/integral-app-2.png'),
        p1: '案例：小蚂蚁服务',
        p2: '合作客户：广东宇千汇信息科技有限公司',
        p3: '案例内容：小蚂蚁服务以积分商城为载体，依托强大的资源整合能力与海量一线品牌权益产品，采用“推荐办卡送积分-积分兑换-回报再推荐”的活动形式，助力广州农业银行实现精准用户拉新，带动业绩增长。',
    }],
    operateNum: 0,
    operateList: [{
        id: 1,
        bg: require('../image/integral-item-bg.png'),
        bg2: require('../image/integral-item-bg-1.png')
    }, {
        id: 2,
        bg: require('../image/integral-item-bg2.png'),
        bg2: require('../image/integral-item-bg-2.png')
    }, {
        id: 3,
        bg: require('../image/integral-item-bg3.png'),
        bg2: require('../image/integral-item-bg-3.png')
    }, {
        id: 4,
        bg: require('../image/integral-item-bg4.png'),
        bg2: require('../image/integral-item-bg-4.png')
    }],
    phoneList: [{
        title: '积分权益对接',
        p1: '通过标准接口协议以及技术手段对接第三方积分平台，提供权益产品和服务，轻松解决企业库存采购的压力，塑造互动感知，提升留存。',
        img: require('../image/integral/logo1.png')
    }, {
        title: '积分商城开发',
        p1: '提供技术、策划支撑，按需定制，配备独立管理后台，可实现电脑+手机双端商城完美适配兼容，促进用户活跃，全面提升商城价值。',
        img: require('../image/integral/logo2.png')
    }],
    demandList: [{
        title: '场景广泛',
        p1: '覆盖衣食住行游购娱等多种行业场景，实用性可观。',
        img: require('../image/integral/img1.png')
    }, {
        title: '创新研发',
        p1: '稳健的技术实力让接入更迅速，保证优质的使用体验。',
        img: require('../image/integral/img2.png')
    }, {
        title: '流程高效',
        p1: '在线化合作流程简单高效，周期结算流程清晰明了。',
        img: require('../image/integral/img3.png')
    }]
}